<template>
    <div>
        <mercur-select
            v-model="shipTo"
            data-test="shipToCountrySelect"
            :class="{'form-invalid': $v.form.shipTo.$error}">
            <template slot="label">Ships to</template>
            <option v-for="(value, key) in items" :key="key" :value="key">
                {{value}}
            </option>
            <template slot="note">
                <span class="form-error" v-if="!$v.form.shipTo.required">Required</span>
            </template>
        </mercur-select>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'ShipToCountrySelector',
    data () {
        return {
            items: null,
        }
    },
    props: {
        shopHash: {
            default: null,
            required: true,
        },
        country: {
            default: null,
        },
        $v: {
            type: Object,
        },
    },
    model: {
        prop: 'country',
        event: 'countryChange',
    },
    watch: {
        shopHash: {
            handler () {
                this.getShops()
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        shipTo: {
            get () {
                return this.country
            },
            set (value) {
                this.$emit('countryChange', value)
            },
        },
    },
    methods: {
        getShops () {
            const url = CONFIG.API.ROUTES.CUSTOM_CARRIER_COSTS.GET_SHIPS_TO.replace('{shopHash}', this.shopHash)
            if (!this.isAllowedTo('getShipsToByShopHash')) {
                return
            }
            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                this.items = data.data
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
}
</script>
