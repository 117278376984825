<template>
    <div>
        <pretty-select
            data-test="agreementShopSelect"
            v-if="items"
            placeholder="Select agreement shop"
            label="applicationName"
            :options="items"
            v-model="selected"
            :required="required"
            :reduce="reducedPayload"
        ></pretty-select>
    </div>
</template>

<script>
import PrettySelect from '../../components/utils/PrettySelect'
import CONFIG from '@root/config'

export default {
    name: 'AgreementShopSelect',
    components: { PrettySelect },
    data () {
        return {
            items: null,
        }
    },
    props: {
        agreementId: {
            default: null,
            required: true,
        },
        required: {
            default: false,
        },
        returnField: {
            default: null,
        },
        selectedShop: {
            default: null,
        },
    },
    model: {
        prop: 'selectedShop',
        event: 'selectedShopChange',
    },
    watch: {
        agreementId: {
            handler () {
                this.getShops()
            },
            immediate: true,
        },
    },
    computed: {
        selected: {
            get () {
                return this.selectedShop
            },
            set (value) {
                this.$emit('selectedShopChange', value)
            },
        },
        reducedPayload () {
            if (this.returnField) {
                return (e) => e[this.returnField]
            }

            return e => e
        },
    },
    methods: {
        getShops () {
            if (!this.isAllowedTo('getAgreementShopsByAgreementId')) {
                return
            }
            const url = CONFIG.API.ROUTES.AGREEMENTS.GET_APPLICATIONS.replace('{agreementId}', this.agreementId)
            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                this.items = data.data.items
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
}
</script>
