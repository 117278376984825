<template>
    <mercur-card class="fill full-height-layout" v-if="!isSupplier">
        <grid-header :quickSearch.sync="filters.search">
            Custom Carrier Costs
            <template slot="actions">
                <mercur-button @click='openDialog' data-test="CustomCarrierCostsOverviewAddCarrierCost" class="btn btn-raised btn-yellow text-uppercase" v-if="isAllowedTo('createCustomCarrierCost')">
                    <i class="fas fa-plus"></i>
                    <span>Add new custom carrier cost</span>
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            ref="grid"
            :url="url"
            :quickSearch="filters.search"
            v-if="isAllowedTo('getCustomCarrierCosts')"
        ></data-table>
        <p class="permission-message" v-else>Not allowed to see this view</p>

        <mercur-dialog :is-open.sync="addCustomCarrierCostDialog" data-test="CustomCarrierCostsOverviewAddCarrierModal" width="200px">
            <div slot="header"><h3>{{draft.isEdit ? 'Edit' : 'Add'}} custom carrier cost </h3></div>
            <form @submit.prevent="submit">
                <div slot="default">
                    <mercur-input
                        v-if="!draft.isEdit"
                        v-model="form.customCarrierCostName"
                        :class="{'form-invalid': $v.form.customCarrierCostName.$error}">
                        Custom carrier cost name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.form.customCarrierCostName.required">Required</span>
                        </template>
                    </mercur-input>
                    <mercur-input
                        v-model="form.customCarrierCost"
                        type="number"
                        data-test="CustomCarrierCostsOverviewAddCarrierCost"
                        :class="{'form-invalid': $v.form.customCarrierCost.$error}">
                        Custom carrier cost
                        <template slot="note">
                            <span class="form-error" v-if="!$v.form.customCarrierCost.required">Required</span>
                        </template>
                    </mercur-input>

                    <template v-if="!draft.isEdit">
                        <agreement-select v-model="form.agreementId" returnField="agreementId" :required="true"></agreement-select>
                        <span class="error pretty-select-error" v-if="$v.form.agreementId.$error">Agreement is required</span>
                        <template v-if="form.agreementId">
                            <agreement-shop-select :agreementId="form.agreementId" v-model="form.shopHash" returnField="shopHash" :required="true"></agreement-shop-select>
                            <span class="error pretty-select-error" v-if="$v.form.shopHash.$error">Shop is required</span>
                        </template>
                        <template v-if="form.agreementId">
                            <agreement-product-select :agreementId="form.agreementId" returnField="productHash" v-model="form.productHash" :required="true" :multiple="true"></agreement-product-select>
                            <span class="error pretty-select-error" v-if="$v.form.productHash.$error">Product is required</span>
                        </template>
                        <template v-if="form.shopHash">
                            <ship-to-country-selector :shopHash="form.shopHash" :$v="$v" v-model="form.shipTo"></ship-to-country-selector>
                        </template>
                    </template>
                </div>
                <div slot="footer" class="text-right">
                    <mercur-button class="btn btn-raised" @click="addCustomCarrierCostDialog = false">Close</mercur-button>
                    <mercur-button class="btn btn-raised" :disabled="loading" type="submit">Save</mercur-button>
                </div>
            </form>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
            <div slot="header"><h3>Are you sure you want to delete this custom carrier cost?</h3></div>
            <div slot="footer">
                <mercur-button @click="deleteConfirmationDialogActive = false" class="btn btn-raised">Calcel</mercur-button>
                <mercur-button @click="deleteCarrierCost" class="btn btn-raised">Delete</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import AgreementSelect from '../../components/agreements/AgreementSelect'
import AgreementShopSelect from '../../components/agreements/AgreementShopSelect'
import AgreementProductSelect from '../../components/agreements/AgreementProductSelect'
import ShipToCountrySelector from '../../components/agreements/ShipToCountrySelector'
import { required } from 'vuelidate/lib/validators'
import { stripProperties } from '@/components/utils/Utils'

export default {
    name: 'CustomCarrierCostsOverview',
    components: { DataTable, GridHeader, AgreementSelect, AgreementShopSelect, AgreementProductSelect, ShipToCountrySelector },
    data () {
        return {
            options: {
                columns: {
                    'Name': {
                        field: 'customCarrierCostName',
                    },
                    'Custom Carrier Cost': {
                        field: 'customCarrierCost',
                    },
                    'Product Name': {
                        field: 'productConfigurationName',
                    },
                    'Ship To': {
                        field: 'shipTo',
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-edit',
                        text: 'Edit',
                        onClick: params => {
                            this.triggerEdit(params.data)
                        },
                        disabled: () => !this.isAllowedTo('updateCustomCarrierCost'),
                    },
                    {
                        icon: 'fas fa-trash',
                        text: 'Delete',
                        onClick: params => {
                            this.triggerDelete(params.data)
                        },
                        disabled: () => {
                            return !this.isAllowedTo('removeCustomCarrierCost')
                        },
                    },
                ],

                quickSearchColumns: ['agreementName'],
            },
            url: CONFIG.API.ROUTES.CUSTOM_CARRIER_COSTS.OVERVIEW,
            filters: {
                search: '',
            },
            supplierPopupActive: false,
            addCustomCarrierCostDialog: false,
            form: {},
            deleteConfirmationDialogActive: false,
            draft: {},
            loading: false,
        }
    },

    validations: {
        form: {
            customCarrierCostName: {
                required,
            },
            customCarrierCost: {
                required,
            },
            productHash: {
                required,
            },
            shopHash: {
                required,
            },
            agreementId: {
                required,
            },
            shipTo: {
                required,
            },
        },
    },

    methods: {
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }
            let url
            if (this.draft.isEdit) {
                if (!this.isAllowedTo('updateCustomCarrierCost')) {
                    this.$root.$emit('notification:global', {
                        message: `Not allowed to update custom carrier cost`,
                        type: 'error',
                    })
                    return
                }
                url = CONFIG.API.ROUTES.CUSTOM_CARRIER_COSTS.UPDATE.replace('{customCarrierCostId}', this.draft.data.customCarrierCostId)
                let payload = Object.assign({}, this.form)
                payload = stripProperties(payload, ['dateCreated', 'dateUpdated', 'totalRows'])
                this.addCarrierCostAction(url, payload)
            } else {
                if (!this.isAllowedTo('createCustomCarrierCost')) {
                    this.$root.$emit('notification:global', {
                        message: `Not allowed to create custom carrier cost`,
                        type: 'error',
                    })
                    return
                }
                url = CONFIG.API.ROUTES.CUSTOM_CARRIER_COSTS.CREATE
                let payload = Object.assign({}, this.form)
                this.form.productHash.forEach((product) => {
                    payload.productHash = product
                    this.addCarrierCostAction(url, payload)
                })
            }
        },

        addCarrierCostAction (url, payload) {
            this.addJob(url)
            this.loading = true
            this.$api.post(url, payload).then(({ data }) => {
                this.addCustomCarrierCostDialog = false
                this.$refs.grid.refreshGrid()
                this.draft = {}
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
                this.loading = false
            })
        },

        triggerEdit (data) {
            this.draft = {
                data: data,
                isEdit: true,
            }
            this.form = JSON.parse(JSON.stringify(this.draft.data))
            this.openDialog()
        },

        triggerDelete (data) {
            this.draft = data
            this.deleteConfirmationDialogActive = true
        },

        deleteCarrierCost () {
            if (!this.isAllowedTo('removeCustomCarrierCost')) {
                this.$root.$emit('notification:global', {
                    message: `Not allowed to remove custom carrier cost`,
                    type: 'error',
                })
                return
            }
            const url = CONFIG.API.ROUTES.CUSTOM_CARRIER_COSTS.DELETE.replace('{customCarrierCostId}', this.draft.customCarrierCostId)
            this.addJob(url)
            this.$api.post(url, []).then(({ data }) => {
                this.$refs.grid.refreshGrid()
                this.deleteConfirmationDialogActive = false
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },

        openDialog () {
            this.$v.$reset()
            this.addCustomCarrierCostDialog = true
        },
    },
}
</script>

<style lang="scss" scoped>
    .pretty-select-error {
        position: absolute;
        transform: translate(0, -100%);
        font-size: 12px;
        letter-spacing: .01em;
    }
</style>
